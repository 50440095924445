import PropTypes from 'prop-types';
import { useRef, useState, useMemo, useCallback, useImperativeHandle, memo, forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { languageNames } from '../../i18n';

/** PropTypes */
import { colorPropTypesNoWhite } from '../../propTypes';

/** Redux */
import { isLogged } from '../../redux/userSlice';

/** Hooks */
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { useCsrfToken } from '../../hooks/useCsrfToken';
import { useFormValidation } from '../../hooks/useFormValidation';

/** Utils */
import { FormSender } from '../../util/Fetch';

/** Components */
import { TextField, TextareaField } from './fields/Field';
import Button from '../Button';
import Link from '../Link';
import CookieNotice from '../CookieNotice';

/**
 * <Form />
 */

// prettier-ignore
const Form = forwardRef(({ type, action, autoComplete, label, buttonLabel, fields, children, color, isDisabled, ...props }, forwardedRef) => {
  const { t: __, i18n } = useTranslation();

  /** CSRF Token, HTTP Referer & reCAPTCHA */
  const csrfToken = useCsrfToken(`post-form-${type}`);
  const httpReferer = useSelector((state) => state.app.currentPage.url);
  const recaptchaKey = useSelector((state) => state.options.settings.recaptcha);
  const loadRecaptcha = useRecaptcha(recaptchaKey);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);
  const userLoggedMessage = `${__('form.Logged in as')} ${user.name}.`;
  /** Load Google reCAPTCHA if the user is logged */
  isUserLogged && loadRecaptcha();

  /** Form */
  const fallbackRef = useRef();
  const formRef = forwardedRef || fallbackRef;
  const formProps = useMemo(
    () => ({
      action: `${process.env.REACT_APP_REST_URL}${action}`,
      method: 'POST',
      autoComplete: autoComplete ? 'on' : 'off',
      noValidate: true,
      onFocus: loadRecaptcha,
    }),
    [action, autoComplete, loadRecaptcha]
  );
  const customFormData = useMemo(() => new FormData(), []);

  /** Validation */
  const validateForm = useFormValidation(fields, action);
  const defaultMessages = useMemo(() => ({ errors: '', status: '', form: '', sent: '' }), []);
  const [messages, setMessages] = useState(defaultMessages);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  /**
   * Submit the form
   *
   * Validate the form, Check Google reCAPTCHA and then send the form
   *
   * @param {SubmitEvent} event  The form submit event
   */
  const submitForm = (event) => {
    event.preventDefault();
    const [isValid, errors] = validateForm(formRef.current);
    setMessages({ ...defaultMessages, errors, form: isValid ? '' : __('form.The form contains errors') });

    if (isValid) {
      window.grecaptcha.ready(() =>
        window.grecaptcha.execute(recaptchaKey, { action }).then((token) => sendForm(token))
      );
    }
  };

  /**
   * Display an error message from the api or a default message
   *
   * @param {object} response  The response trom the api
   * @param {string} defaultMessage  The default message
   */
  const setSenderError = useCallback(
    (response, defaultMessage) => {
      setMessages({
        ...defaultMessages,
        form: response.data ? response.data.message : response.error ?? defaultMessage,
      });
    },
    [defaultMessages]
  );

  /**
   * Process the form sender response
   *
   * @param {JSON} response  The form sender response
   */
  const onSenderResponse = useCallback(
    (response) => {
      const { sent, data } = response;
      setIsSending(false);
      props.onResponse(sent && (data.isSent || data.isSaved));
      if (sent) {
        if (data.isSent) {
          setIsSent(true);
          setMessages({ ...defaultMessages, sent: __('form.Message sent') });
          props.onSent(data);
        } else if (data.isSaved) {
          setIsSent(true);
          setMessages({ ...defaultMessages, sent: response.data.message });
          props.onSent(data);
        } else if (data.isLoggedIn && data.name) {
          setMessages(defaultMessages);
          props.onLogin(data);
        } else if (data.isLoggedOut) {
          setMessages({ ...defaultMessages, status: __('form.You have been disconnected') });
          props.onLogout(data);
        } else if (!data.isLoggedIn) {
          setSenderError(response, __('form.Error during authentication'));
        }
      } else {
        setSenderError(response, __('form.Error sending your message'));
      }
    },
    [__, defaultMessages, props, setSenderError]
  );

  /**
   * Send the form
   *
   * @param {string} captchaToken  The reCAPTCHA token
   */
  const sendForm = useCallback(
    (captchaToken) => {
      /** Set the form data */
      const formData = customFormData.has('data') ? customFormData : new FormData(formRef.current);
      formData.append('type', type);
      formData.append('language', languageNames[i18n.language]);
      formData.append('action', action === 'login' && isUserLogged ? 'logout' : action);

      /** Create the user header string and remove login & password from the form data */
      const userHeader = isUserLogged
        ? `${user.email}:${user.token}`
        : `${formData.get('login')}:${formData.get('password')}`;
      if (action === 'login') {
        formData.delete('login');
        formData.delete('password');
      }

      /** Send the form */
      const sender = new FormSender(formData, formProps.action);
      sender.addHeader('X-CSRF-TOKEN', csrfToken);
      sender.addHeader('X-HTTP-REFERER', httpReferer);
      sender.addHeader('X-APP-LANGUAGE', i18n.language);
      captchaToken && sender.addHeader('X-CAPTCHA-TOKEN', captchaToken);
      (action === 'login' || action.startsWith('user/')) &&
        sender.addHeader('X-APP-USER', encodeURIComponent(window.btoa(userHeader)));
      sender.addListener('response', (data) => onSenderResponse(data));
      sender.send();
      setIsSending(true);
      props.onSend();

      /** Set the status message */
      setMessages({
        ...defaultMessages,
        status:
          action === 'login'
            ? !isUserLogged
              ? __('form.Authentication in progress')
              : __('form.Logout in progress')
            : __('form.Sending in progress'),
      });
    },
    [__, action, csrfToken, customFormData, defaultMessages, formProps.action, formRef,
      httpReferer, i18n.language, isUserLogged, onSenderResponse, props, type, user] // prettier-ignore
  );

  /** Expose methods and the form status to the referrer */
  useImperativeHandle(
    forwardedRef,
    () => ({
      setMessages,
      defaultMessages,
      setFormData: (name, value) => customFormData.set(name, value),
      setFormFile: (name, value, fileName) => customFormData.set(name, value, fileName),
      sendForm,
      getIsSending: () => isSending,
      getIsSent: () => isSent,
      reset: () => {
        setIsSent(false);
        setMessages(defaultMessages);
        for (const key of customFormData.keys()) {
          customFormData.delete(key);
        }
      },
    }),
    [defaultMessages, sendForm, customFormData, isSending, isSent]
  );

  return (
    <form
      ref={formRef}
      {...formProps}
      className={`form ${type}-form ${isUserLogged ? 'is-logged' : ''} ${isDisabled ? 'is-disabled' : ''}`}
      onSubmit={(event) => (props.onSubmit ? props.onSubmit(event) : submitForm(event))}
    >
      <h3 className="visually-hidden">{label}</h3>
      {fields
        ? fields.map((field, index) => (
            <Fragment key={index}>
              {['text', 'email', 'url', 'tel', 'password'].includes(field.type) ? (
                <TextField
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  placeholder={field.placeholder}
                  isRequired={field.isRequired}
                  maxLength={field.maxLength}
                  errorMessage={messages.errors[field.name]}
                  isDisabled={isDisabled || isSent || isSending}
                />
              ) : field.type === 'textarea' ? (
                <TextareaField
                  name={field.name}
                  label={field.label}
                  placeholder={field.placeholder}
                  isRequired={field.isRequired}
                  errorMessage={messages.errors[field.name]}
                  isDisabled={isDisabled || isSent || isSending}
                />
              ) : (
                <></>
              )}
            </Fragment>
          ))
        : children && <>{children}</>}

      {(isSent || (isUserLogged && action === 'login')) && (
        <p className="form-sent">{isSent ? messages.sent : userLoggedMessage}</p>
      )}
      {messages.status && <p className="form-status">{messages.status}</p>}
      {messages.form && <p className="form-error">{messages.form}</p>}

      <div className={`form-submit ${isSent ? 'is-sent' : ''}`}>
        {(!isUserLogged || action === 'contact') && (
          <div>
            <FormCookieNotice />
          </div>
        )}
        <div>
          <Button
            layout="outline"
            color={color}
            type="submit"
            className="submit-button"
            isDisabled={isDisabled || isSent || isSending}
          >
            {buttonLabel ? buttonLabel : __('button.Send')}
          </Button>
        </div>
      </div>
    </form>
  );
});
Form.displayName = 'Form';

/**
 * You have to provide either
 * - the fields prop
 * - the children prop
 */
Form.propTypes = {
  /** The type of the form */
  type: PropTypes.oneOf(['contact', 'create-festival', 'login', 'create-event']).isRequired,
  /** The app route to post the form to */
  action: PropTypes.oneOf(['contact', 'login', 'user/create-event']).isRequired,
  /** Whether to use autocomplete for the form */
  autoComplete: PropTypes.bool,
  /** A label for the form */
  label: PropTypes.string.isRequired,
  /** The label of the button */
  buttonLabel: PropTypes.string,
  /** The fields of the form */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name attribute */
      name: PropTypes.string.isRequired,
      /** The type of the field */
      type: PropTypes.oneOf(['text', 'email', 'url', 'tel', 'password', 'textarea']).isRequired,
      /** A label for the field */
      label: PropTypes.string.isRequired,
      /** A placeholder for the field */
      placeholder: PropTypes.string,
      /** The max length of the field */
      maxLength: PropTypes.number,
      /** Whether the field is required */
      isRequired: PropTypes.bool,
    })
  ),
  /** The children of the element */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Whether the form should be disabled */
  isDisabled: PropTypes.bool,
  /** The color of the button */
  color: colorPropTypesNoWhite,
  /** A callback for the onSubmit event */
  onSubmit: PropTypes.func,
  /** A callback for the onSend event */
  onSend: PropTypes.func,
  /** A callback for the onResponse event */
  onResponse: PropTypes.func,
  /** A callback for the onSent event */
  onSent: PropTypes.func,
  /** A callback for the onLogin event */
  onLogin: PropTypes.func,
  /** A callback for the onLogout event */
  onLogout: PropTypes.func,
};

Form.defaultProps = {
  autoComplete: false,
  isDisabled: false,
  color: 'green',
  onSend: () => {},
  onResponse: () => {},
  onSent: () => {},
  onLogin: () => {},
  onLogout: () => {},
};

/**
 * <FormCookieNotice />
 */

const FormCookieNotice = (props) => {
  const { t: __ } = useTranslation();

  return (
    <CookieNotice>
      <h6 className="h7">{__('cookie.Cookie information')}</h6>
      <p>
        {__('cookie.Forms are protected...')}
        <Link url="https://www.google.com/policies/privacy/" target="_blank" title={__('cookie.Privacy Policy')} />
        {__('cookie.and')}
        <Link url="https://www.google.com/policies/terms/" target="_blank" title={__('cookie.Terms of Service')} />
        {__('cookie.apply')}
      </p>
    </CookieNotice>
  );
};

export default memo(Form);
